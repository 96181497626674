import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { idCheck, logOut } from '../redux/auth.redux';
import {retrieveMyAccount, changeMyAccount, registerProduct, clearError, refreshed, needsRefresh, deactivate_software} from '../redux/user.redux';
import Layout from '../components/Layout';
import AccountStyle from './account.module.css';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import {countries} from '../components/countries';
import {faMinusCircle, faKey} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const initState = {
    addressPopover: false,
    productPopover: false,
    emailPopover: false,
    infoSynced: false,
    refreshing: false,
    registeredProducts: [],
    ownedSoftwares: [],
}

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = initState;
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.props.needsRefresh();
        if (!this.props.auth.isLoggedIn) {
            navigate('/login');
        }
    }

    componentDidUpdate() {
        if (this.props.user.err) {
            alert(this.props.user.err.data.message);
            this.setState(state=> ({...state, loginEmail: this.props.user.data.email}));
            this.props.clearError();
        }
        if (this.props.user.needsRefresh) {
            this.props.retrieveMyAccount();
            this.setState(initState);
            this.props.refreshed();
        }
        if (this.props.user.retrieveSuccess) {
            this.syncState();
        }
    }

    parseDate = (d) => {
        return d.split('T')[0];
    }

    handleAddressPopover = () => {
        this.setState(state => ({...state, addressPopover: true}));
    }

    handleEmailPopover = () => {
        this.setState(state => ({...state, emailPopover: true}));
    }

    handleRegisterPopover = () => {
        this.setState(state => ({...state, productPopover: true}));
    }

    handleNamePopover = () => {
        this.setState(state => ({...state, namePopover: true}));
    }

    validateEmail = (email) => {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    handlePopoverClose = () => {
        this.setState(state => ({...state, emailPopover: false, addressPopover: false, productPopover: false, namePopover: false,}));
    }


    handleAddressPopoverClose = () => {
        let error = false
        error =  !this.state.name;
        if (!this.state.name) {
            this.setState(state => ({...state, ['nameError']: true}));
            error = true;
        }
        if (!this.validateEmail(this.state.email)) {
            this.setState(state => ({...state, ['emailError']: true}));
            error = true;
        }
        if (!this.state.addressLine1) {
            this.setState(state => ({...state, ['addressLine1Error']: true}));
            error = true;
        }
        if (!this.state.city) {
            this.setState(state => ({...state, ['cityError']: true}));
            error = true;
        }
        if (!this.state.state) {
            this.setState(state => ({...state, ['stateError']: true}));
            error = true;
        }
        if (!this.state.country) {
            this.setState(state => ({...state, ['countryError']: true}));
            error = true;
        }
        if (!error) {
            this.handlePopoverClose();
            const userInfo = {
                address: {
                    name: this.state.name,
                    email: this.state.email,
                    line1: this.state.addressLine1,
                    line2: this.state.addressLine2,
                    city: this.state.city,
                    state: this.state.state,
                    postal_code: this.state.postalCode,
                    country: this.state.country,
                }
            }
            this.props.changeMyAccount(userInfo);
        }
    }

    handleEmailPopoverClose = () => {
        if (!this.validateEmail(this.state.loginEmail)) {
            this.setState(state => ({...state, ['loginEmailError']: true}));
        } else {
            if (confirm(`This will be your new login credential, are you sure?`)) {
                this.props.changeMyAccount({email: this.state.loginEmail});
            }
            this.handlePopoverClose();
        }
    }

    handleRegisterPopoverClose = () => {
        let error = false;
        if (!this.state.newProductSN) {this.setState({newProductSNError: true})};
        if (error) {
            return;
        }
        this.props.registerProduct({'serialNumber': this.state.newProductSN});
        this.handlePopoverClose();
    }

    handleNamePopoverClose = () => {
        let error = false;
        if (!this.state.newName) {this.setState({newNameError: true})};
        if (error) {
            return;
        }
        const split = this.state.newName.split(' ');
        const obj = {
            firstName: split[0],
            lastName: split[1] ? split[1] : ""
        }
        this.props.changeMyAccount(obj);
        this.handlePopoverClose();
    }
    

    handleChange = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value, [`${name}Error`]: false }));
    };

    deactivateSoftware = (softwareName, deviceName, softwareID, deviceID) => {
        if (confirm(`Sure to deactivate ${softwareName} on ${deviceName}?`))
            this.props.deactivate_software(softwareID, deviceID);
    }

    copyActivationCodeToClipboard = (code) => {
        if (document) {
            var dummy = document.createElement('input');
            document.body.appendChild(dummy);
            dummy.setAttribute('style', 'position: absolute; left: -1000px; top: -1000px');
            dummy.setAttribute('id', 'dummy_id');
            document.getElementById('dummy_id').value = code;
            dummy.select();
            document.execCommand('copy');
            document.body.removeChild(dummy);
            alert('Serial code copied');
        }
    }

    handleCancel = () => {
        const d = this.props.user.data;
        this.setState(state=>({...state, 
            loginEmail: d.email,
            name: (d.address && d.address.name) ? d.address.name : null, 
            email: (d.address && d.address.email) ? d.address.email : null,
            addressLine1: (d.address && d.address.line1) ? d.address.line1 : null,
            addressLine2: (d.address && d.address.line2) ? d.address.line2 : null,
            city: (d.address && d.address.city) ? d.address.city : null,
            state: (d.address && d.address.state) ? d.address.state : null,
            country: (d.address && d.address.country) ? d.address.country : null,
            postalCode: (d.address && d.address.postal_code) ? d.address.postal_code : null,
            orders: d.orders,
            registeredProducts: d.ownedProducts,
            ownedSoftwares: d.ownedSoftwares,
        }));
        this.handlePopoverClose();
    }

    syncState = () => {
        if (!this.state.infoSynced && this.props.user.data) {
            const d = this.props.user.data;
            this.setState(state=>({...state, 
                loginEmail: d.email,
                name: (d.address && d.address.name) ? d.address.name : null, 
                email: (d.address && d.address.email) ? d.address.email : null,
                addressLine1: (d.address && d.address.line1) ? d.address.line1 : null,
                addressLine2: (d.address && d.address.line2) ? d.address.line2 : null,
                city: (d.address && d.address.city) ? d.address.city : null,
                state: (d.address && d.address.state) ? d.address.state : null,
                country: (d.address && d.address.country) ? d.address.country : null,
                postalCode: (d.address && d.address.postal_code) ? d.address.postal_code : null,
                orders: d.orders,
                registeredProducts: d.ownedProducts,
                ownedSoftwares: d.ownedSoftwares,
                infoSynced: true
            }));
        }
    }

    render() {
        const userData = this.props.user.data;
        const {addressPopover, productPopover, emailPopover, namePopover} = this.state;
        const isPopoverOpen = addressPopover || productPopover || emailPopover || namePopover;
        if (!this.props.auth.isLoggedIn || !userData) {
            return null;
        }
        const country_menu = (
            countries.map((item, i) => {
                return (
                    <MenuItem key={i} value={item.code}>{item.name}</MenuItem>
                )
            })
        )

        const addressPopoverComponent = (
            <Dialog
                aria-labelledby="addressEditor" 
                aria-describedby="edit address"
                className={AccountStyle.modal}
                open = {addressPopover}
                onClose = {this.handleCancel}
            >
                <Fade in={isPopoverOpen}>
                    <div className={AccountStyle.addressModalContainer}>
                        <h4 style={{marginBottom: '16px', textAlign:'center'}}>Address</h4>
                        <div style={{marginBottom: '24px'}}>
                            <TextField 
                                label="Name"
                                type="name"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                autoComplete="name"
                                margin="dense"
                                variant="outlined"
                                error={this.state.nameError}
                                required={true}
                                fullWidth
                            />
                            <TextField 
                                label="Email"
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange('email')}
                                autoComplete="email"
                                margin="dense"
                                variant="outlined"
                                error={this.state.emailError}
                                required={true}
                                fullWidth
                            />
                            <TextField 
                                label="Address Line 1"
                                type="address-line1"
                                name="address-line1"
                                value={this.state.addressLine1}
                                onChange={this.handleChange('addressLine1')}
                                autoComplete="address-line1"
                                margin="dense"
                                variant="outlined"
                                error={this.state.addressLine1Error}
                                required={true}
                                fullWidth
                            />
                            <TextField 
                                label="Address Line 2"
                                type="address-line2"
                                name="address-line2"
                                value={this.state.addressLine2}
                                onChange={this.handleChange('addressLine2')}
                                autoComplete="address-line2"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                            />
                            <Grid container spacing={1} allignItems='center'>
                            <Grid item xs={5}>
                                <TextField 
                                    label="City"
                                    type="address-level2"
                                    name="address-level2"
                                    value={this.state.city}
                                    onChange={this.handleChange('city')}
                                    autoComplete="address-level2"
                                    margin="dense"
                                    variant="outlined"
                                    error={this.state.cityError}
                                    required={true}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField 
                                    label="State"
                                    type="address-level1"
                                    name="address-level1"
                                    value={this.state.state}
                                    onChange={this.handleChange('state')}
                                    autoComplete="address-level1"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    error={this.state.stateError}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField 
                                    label="Zip code"
                                    type="postal-code"
                                    name="postal-code"
                                    value={this.state.postalCode}
                                    onChange={this.handleChange('postalCode')}
                                    autoComplete="postal-code"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <TextField 
                            label="Country"
                            select
                            value={this.state.country}
                            SelectProps = {{
                                renderValue : () => {return (this.state.country)}
                            }}
                            onChange={this.handleChange('country')}
                            autoComplete="country"
                            error={this.state.countryError}
                            margin="dense"
                            variant="outlined"
                            required={true}
                            fullWidth
                        >
                            {country_menu}
                        </TextField>
                        </div>
                        <div style={{textAlign:'center'}}>
                            <button onClick={this.handleAddressPopoverClose}>Save</button>
                            <button onClick={this.handleCancel} style={{backgroundColor:'transparent', color: 'black', width: 'auto', padding: '0px 16px', borderColor:'transparent'}}>Cancel</button>
                        </div>
                    </div>
                </Fade>
            </Dialog>
        )

        const emailPopoverComponent = (
            <Dialog
                aria-labelledby="emailEditor" 
                aria-describedby="edit email"
                className={AccountStyle.modal}
                open = {emailPopover}
                onClose = {this.handleCancel}
            >
                <Fade in={isPopoverOpen}>
                    <div className={AccountStyle.emailModalContainer}>
                        <h4 style={{marginBottom: '16px', textAlign:'center'}}>New Email</h4>
                        <TextField 
                            label="Email"
                            type="email"
                            name="email"
                            value={this.state.loginEmail}
                            onChange={this.handleChange('loginEmail')}
                            autoComplete="email"
                            margin="dense"
                            variant="outlined"
                            error={this.state.loginEmailError}
                            required={true}
                            fullWidth
                        />
                        <p style={{color: '#7b5ac8', textAlign: 'center', margin: '12px', fontSize: '12px'}}>You're changing the email address for login</p>
                        <div style={{textAlign:'center'}}>
                            <button onClick={this.handleEmailPopoverClose}>Save</button>
                            <button onClick={this.handleCancel} style={{backgroundColor:'transparent', color: 'black', width: 'auto', padding: '0px 16px', borderColor:'transparent'}}>Cancel</button>
                        </div>
                    </div>
                </Fade>
            </Dialog>
        )

        const namePopoverComponent = (
            <Dialog
                aria-labelledby="nameEditor" 
                aria-describedby="edit name"
                className={AccountStyle.modal}
                open = {namePopover}
                onClose = {this.handleCancel}
            >
                <Fade in={isPopoverOpen}>
                    <div className={AccountStyle.nameModalContainer}>
                        <h4 style={{marginBottom: '16px', textAlign:'center'}}>Change Name</h4>
                        <TextField 
                            label="Your Name"
                            type="name"
                            name="name"
                            value={this.state.newName}
                            onChange={this.handleChange('newName')}
                            autoComplete="name"
                            margin="dense"
                            variant="outlined"
                            error={this.state.newNameError}
                            required={true}
                            fullWidth
                        />
                        <div style={{textAlign:'center'}}>
                            <button onClick={this.handleNamePopoverClose}>Save</button>
                            <button onClick={this.handleCancel} style={{backgroundColor:'transparent', color: 'black', width: 'auto', padding: '0px 16px', borderColor:'transparent'}}>Cancel</button>
                        </div>
                    </div>
                </Fade>
            </Dialog>
        )

        const productPopoverComponent = (
            <Dialog
                aria-labelledby="productEditor" 
                aria-describedby="register product"
                className={AccountStyle.modal}
                open = {productPopover}
                onClose = {this.handleCancel}
            >
                <Fade in={isPopoverOpen}>
                    <div className={AccountStyle.productModalContainer}>
                        <h4 style={{marginBottom: '16px', textAlign:'center'}}>Register Product</h4>
                        <TextField 
                            label="Serial Number"
                            type="sn"
                            name="sn"
                            value={this.state.newProductSN}
                            onChange={this.handleChange('newProductSN')}
                            margin="dense"
                            variant="outlined"
                            error={this.state.newProductSNError}
                            required={true}
                            fullWidth
                        />
                        <div style={{textAlign:'center', marginTop: '16px'}}>
                            <button onClick={this.handleRegisterPopoverClose}>Register</button>
                            <button onClick={this.handleCancel} style={{backgroundColor:'transparent', color: 'black', width: 'auto', padding: '0px 16px', borderColor:'transparent'}}>Cancel</button>
                        </div>
                    </div>
                </Fade>
            </Dialog>
        )

        const contactInfo = () => {
            if (this.state.infoSynced) {
                return (
                    <div>
                        <p>{this.state.name}</p>
                        <p>{this.state.email}</p>
                        <p>{this.state.addressLine1}{this.state.addressLine2 ? `, ${this.state.addressLine2}` : null}</p>
                        <p>{this.state.city}{this.state.state ? `, ${this.state.state}`:null}{this.state.postalCode ? `, ${this.state.postalCode}`:null}{this.state.country ? `, ${this.state.country}`:null}</p>
                        <button onClick={this.handleAddressPopover}>Edit</button>
                    </div>
                )
            }
        }

        
        
        const products = (
            <table style={{fontFamily: `'roboto', sans-serif`}}>
                <tbody>
                    {this.state.registeredProducts.length > 0 ?
                    <tr style={{borderStyle:'hidden'}}>
                        <th style={{fontWeight: 300, color:'rgb(50,50,50)'}}>Name</th>
                        <th style={{fontWeight: 300, color:'rgb(50,50,50)'}}>SerialNumber</th>
                    </tr> : null }
                    {this.state.registeredProducts.map((item, i)=> (
                        <tr key={i}>
                            <td style={{fontWeight: 500}}>{item.name}</td>
                            <td>{item.serialNumber}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )

        const softwares = (
            <table style={{fontFamily: `'roboto', sans-serif`}}>
                <tbody>
                    {this.state.ownedSoftwares.length > 0 ?
                    <tr style={{borderStyle:'hidden'}}>
                        <th style={{fontWeight: 300, color:'rgb(50,50,50)', textAlign: 'center'}}>Name</th>
                        <th style={{fontWeight: 300, color:'rgb(50,50,50)', textAlign: 'center'}}>License</th>
                        <th style={{fontWeight: 300, color:'rgb(50,50,50)', textAlign: 'center'}}>Activated on</th>
                    </tr> : null }
                    {this.state.ownedSoftwares.map((item, i)=> (
                        <tr key={i}>
                            <td style={{fontSize: '14px', fontWeight: 500, textAlign: 'center'}}>
                                <div className={AccountStyle.copyCodeBtn} onClick={()=>this.copyActivationCodeToClipboard(item.serialNumber)}><FontAwesomeIcon icon={faKey} size='lg'/></div>
                                {item.name}
                            </td>
                            <td style={{fontSize: '14px', color: 'rgb(80,80,80)', textAlign:'center'}}>
                                {item.authType}{item.expiration ? `(expiresAt: ${expiration})` : ''}
                            </td>
                            { item.active_devices.length > 0 ?
                            item.active_devices.map((device, i) => (
                                <tr key={"device" + i}>
                                    <td style={{fontSize: '14px', fontWeight: 500, textAlign: 'center'}}>
                                        {device.deviceName}
                                        <div className={AccountStyle.deleteBtn} onClick={()=>this.deactivateSoftware(item.name, device.deviceName, item._id, device._id)}><FontAwesomeIcon icon={faMinusCircle} size='lg'/></div>
                                    </td>
                                </tr>
                            ))
                            : <td>Not activated</td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        )


        return (
            <Layout>
                <div className={AccountStyle.container}>
                    <div className={AccountStyle.title}>
                        <h3>Welcome back {userData.firstName} {userData.lastName}.</h3>
                        <p>Thanks for being our customer since {this.parseDate(userData.date_joined)}.</p>
                        <button onClick={this.handleNamePopover}>Change Name</button>
                        {namePopoverComponent}
                    </div>
                    <div className={AccountStyle.accountContainer}>
                        <div className={AccountStyle.account}>
                            <h4>Account Info</h4>
                            <p>Available credit: ${userData.credit.toFixed(2)}</p>
                            <div>
                                <p>Email: {this.state.loginEmail}</p>
                                <button onClick={this.handleEmailPopover}>Change Email</button>
                                {emailPopoverComponent}
                            </div>
                        </div>
                    </div>
                    <div className={AccountStyle.contactContainer}>
                        <div className={AccountStyle.contact}>
                            <h4>Shipping address</h4>
                            {contactInfo()}
                            {addressPopoverComponent}
                        </div>
                    </div>
                    <div className={AccountStyle.productsContainer}>
                        <div className={AccountStyle.products}>
                            <h4>Registered Products</h4>
                            {products}
                            <button onClick={this.handleRegisterPopover}>Register New Product</button>
                            {productPopoverComponent}
                        </div>
                    </div>
                    <div className={AccountStyle.softwareContainer}>
                        <div className={AccountStyle.sortwares}>
                            <h4>Registered Softwares</h4>
                            {softwares}
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

const mapStatetoProps = (state) => {return { auth : state.auth, user: state.user }};
const actionCreators = { idCheck, logOut, retrieveMyAccount, changeMyAccount, registerProduct, clearError, refreshed, needsRefresh, deactivate_software };
export default connect(mapStatetoProps, actionCreators)(Account);